import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import PageBuilder from "../components/pageBuilder";
import Vid from "../../assets/video/homepage.mp4";
import Poster from "../../assets/images/homepage-poster.jpg";
import RecentPosts from "../components/recentPosts";

export const query = graphql`
  query {
    sanityHomepage {
      title
      _rawHero
      _rawBody(resolveReferences: { maxDepth: 10 })
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const PageTemplate = (props) => {
  const page = props.data.sanityHomepage;
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null;
  const description = page.seo && page.seo.description ? page.seo.description : null;
  const image =
    page.seo && page.seo.openGraphImage && page.seo.openGraphImage.asset !== null
      ? page.seo.openGraphImage.asset.url
      : null;

  return (
    <Layout theme="dark">
      <Helmet>
        {page.seo && page.seo.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SEO title={page.title} customTitle={customTitle} description={description} image={image} />
      <Hero {...page._rawHero} theme="dark" video={Vid} poster={Poster} graph="radarWhite" />
      <PageBuilder blocks={page._rawBody} />
      <RecentPosts />
    </Layout>
  );
};

export default PageTemplate;
