import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import styles from "./recentPosts.module.scss"

const RecentPosts = () => {
  const query = useStaticQuery(
    graphql`
      {
        allSanityBlogInsight(limit: 4, sort: {order: DESC, fields: publishDate}) {
          edges {
            node {
              _id
              title
              slug { current }
              category {
                slug { current }
              }
              _rawAuthor(resolveReferences: {maxDepth: 10})
              publishDate(formatString: "MMM D, YYYY")
            }
          }
        }
        allSanityResource(limit: 7, sort: {order: DESC, fields: publishDate}) {
          edges {
            node {
              _id
              title
              slug { current }
              category {
                slug { current }
              }
            }
          }
        }
      }
    `
  )

  const blogs = query.allSanityBlogInsight.edges.map(({node}) => node)
  const resources = query.allSanityResource.edges.map(({node}) => node)
  return (
    <section className={styles.recentPosts} data-noborder="true">
      <div className="grid">
        <div className="gridItem large-6 xlarge-5 xlarge-offset-1">
          <div className={styles.recentPostsColumn}>
            <h3>Latest Insight Blog Posts</h3>
            <ul>
              {blogs.map(blog =>
                <li key={blog._id}>
                  <Link to={`/blog/${blog?.category?.slug?.current}/${blog?.slug?.current}/`}>
                    <p>{blog.title}</p>
                    <span>{blog._rawAuthor && `By ${blog._rawAuthor.name}`} | {blog.publishDate}</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="gridItem large-6 xlarge-5">
          <div className={styles.recentPostsColumn} data-theme="brandGamma">
            <h3>Latest Resources</h3>
            <ul>
              {resources.map(resource =>
                <li key={resource._id}>
                  <Link to={`/resources/${resource?.category?.slug?.current}/${resource?.slug?.current}/`}>
                    <p>{resource.title}</p>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RecentPosts
